@import 'styles/all.scss';

.CheckoutForm_customerFormAgreement {
  display: flex;
  margin-top: rem(12px);
  margin-bottom: rem(12px);
  font-size: 14px;
  padding: 12px 0 12px;
  a {
    padding: 0;
    height: auto;
    line-height: auto;
  }

  .CheckoutForm_checkoutFormCheckboxWrapper {
    padding-right: rem(15px);
  }

  .ant-checkbox, .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-radius: rem(3px);
      width: rem(25px);
      height: rem(25px);
    }
  }
}
