@use 'sass:map';
@import 'styles/all.scss';

.StoreCheckout-fix-actions{
  position: fixed;
  bottom: 0;
  left: 0;
  padding: rem(16px 32px);
  width: 100%;
  text-align: center;
  box-shadow: 0px -4px 4px $color-nude;
  background-color: $color-white;
  z-index: 100;

  &-content {  
    width: 100%;
    margin: 0 auto;
    max-width: map.get($breakpoints, 'xxl');
  }
}

.StoreCheckout-form_mobile{
  @include media('<=md') {
    display: flex;
    flex-direction: column-reverse;
  }
  
}