@import 'styles/all.scss';

.StoreCheckoutFailure {
  .StoreCheckoutFailure_layout {
    background-image: url('./Background.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    position: relative;
    
    @include media('>md') {
      background-size: auto auto;
      background-position: center center;
    }
  }

  .StoreLayoutContent_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(100px);
    text-align: center;
  }

  .StoreCheckoutFailure_title {
    font-weight: $font-weight-bold;
    font-size: rem(36px);
    line-height: rem(46px);
  }

  .StoreCheckoutFailure_text {
    font-size: rem(16px);
    line-height: rem(26px);
    max-width: rem(550px);
  }

  .StoreCheckoutFailure_button {
    height: rem(45px);
    width: rem(250px);
    margin-top: rem(30px);
  }
}
