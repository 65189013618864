@use 'sass:map';
@import 'styles/all.scss';

.OrderProduct_image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: rem(50px);
    height: rem(50px);
    border-radius: rem(10px);
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
